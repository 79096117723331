@use '@angular/material' as mat;
@import 'theme';

// tooltip start

mat-tooltip-component {
  .mat-tooltip {
    margin: 4px 0 0;
    line-height: 10px;
  }
}

.mat-tooltip-panel {
  white-space: pre-line;

  .mat-tooltip {
    color: rgba(255, 255, 255, 87%);
  }
}

.mat-tooltip {
  &.tooltip-oneline {
    width: auto;
    max-width: none;
  }

  &.tooltip-multiline {
    max-width: 350px;
    line-height: 14px;
    text-align: left;
  }

  &.moved-down {
    margin-bottom: -26px;
  }

  &.tooltip-right {
    margin-left: 4px;
  }
}

// tooltip end

.mat-focused .mat-form-field-required-marker {
  color: mat.get-color-from-palette($candy-app-primary);
}

px-datepicker-container px-datepicker .md-datepicker-input-container input {
  //line-height: 22px;
  //height: 26px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  //padding-bottom: 2px;
}

.transparent-backdrop {
  background: transparent;
}

.mat-raised-button {
  transform: none;
}

.mat-icon {
  > svg {
    display: block;
  }
}

button,
a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    display: inline-flex;
    flex: none;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    min-height: 36px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
    border-radius: 5px;
    box-shadow: none !important;
    transition: all 0.3s ease;

    &[disabled] {
      box-shadow: none;
    }

    .mat-button-focus-overlay,
    &:focus .mat-button-focus-overlay,
    &.mat-primary .mat-button-focus-overlay {
      background-color: transparent;
      opacity: 0;
    }

    .mat-button-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &.dropdown-item {
      border-radius: 0 !important;

      .mat-button-wrapper {
        justify-content: flex-start;
      }
    }

    > span[class^='pss-'] {
      margin-right: 8px;
      margin-bottom: 1px;
    }
  }

  &.mat-button {
    &:hover:not([disabled]) {
      background-color: rgba(158, 158, 158, 20%);
    }

    &.dark-btn {
      color: rgb(255, 255, 255);
      background-color: rgb(98, 98, 98);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 12%), 0 2px 2px 0 rgba(0, 0, 0, 24%);

      &:hover {
        background-color: rgb(97, 97, 97);
      }

      &[disabled] {
        color: rgba(0, 0, 0, 26%);
        background: mat.get-color-from-palette($candy-app-accent, 'raised-disabled');
        box-shadow: none;
      }

      .with-ico {
        color: mat.get-color-from-palette($candy-app-accent, 'raised-disabled-contrast');
      }
    }
  }

  &.mat-raised-button,
  &.mat-flat-button {
    padding: 0 6px;
    background-color: mat.get-color-from-palette($candy-app-accent, 300);

    &:hover {
      //background-color: mat.get-color-from-palette($candy-app-accent, lighter);
      background-color: rgba(0, 0, 0, 6%);
    }

    &[disabled][disabled] {
      color: mat.get-color-from-palette($candy-app-accent, 'disabled-contrast');
    }

    &.mat-primary[disabled] {
      color: mat.get-color-from-palette($candy-app-accent, 'disabled-contrast');
      background: mat.get-color-from-palette($candy-app-accent, 'disabled');
    }

    &:not([disabled]),
    &:not([disabled]):active {
      //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    }

    &.mat-accent:not([disabled]) {
      &:hover {
        background-color: mat.get-color-from-palette($candy-app-accent, darker);
      }
    }

    &.mat-primary:not([disabled]) {
      &:hover {
        color: mat.get-color-from-palette($candy-app-primary, darker-contrast);
        background-color: mat.get-color-from-palette($candy-app-primary, darker);
      }
    }
  }

  &.px-no-mat-focus {
    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  &.px-btn-paddings {
    min-width: 0;
    padding: 0 22px;
  }
}

.mat-form-field {
  &.app-form-field {
    width: 100%;

    [matsuffix] {
      font-size: 11px;
    }

    .valid-icon,
    .invalid-icon {
      display: none;
    }

    &.valid {
      .valid-icon {
        display: block;
        color: $px-input-valid-color;
      }
    }

    &.invalid {
      .invalid-icon {
        display: block;
        color: mat.get-color-from-palette($candy-app-warn, 500);
      }
    }
  }

  &.mat-form-field-appearance-legacy {
    // input + label and hint
    input {
      //padding-left: 2px;
      //padding-right: 2px;
      line-height: 16px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 17px;
    }

    .mat-form-field-underline {
      background-color: rgba(0, 0, 0, 12%);
    }

    .mat-form-field-infix {
      padding: 7px 0;
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 5px;

      .mat-hint {
        padding-right: 2px;
        color: mat.get-color-from-palette($candy-app-accent, '50-contrast');
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

// accordion
.mat-accordion {
  .mat-expansion-panel {
    border: 1px solid rgba(255, 255, 255, 80%);
    border-radius: 3px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 24%), 0 0 2px 0 rgba(0, 0, 0, 12%);
  }

  .mat-expansion-panel-spacing {
    margin: 10px 0 0;
  }

  mat-expansion-panel-header {
    &:hover {
      background-color: mat.get-color-from-palette($candy-app-accent, 'A100') !important;
    }
  }
}

.px-menu-panel {
  min-width: 112px;
  max-width: 280px;
  min-height: 64px;
  max-height: calc(100vh - 48px);
  overflow: auto;
  background-color: white;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 20%),
    0px 4px 5px 0px rgba(0, 0, 0, 14%),
    0px 1px 10px 0px rgba(0, 0, 0, 12%);
  -webkit-overflow-scrolling: touch;
}

.px-menu-content:not(:empty) {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-menu-item {
  position: relative;

  //text-overflow: ellipsis;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 48px;
  padding: 0 16px;
  overflow: hidden;
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 48px;
  white-space: nowrap;
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover:not([disabled]) {
    background: rgba(0, 0, 0, 4%);
  }
}
