$fontFamilyBase: 'Roboto', sans-serif;
$mainContainerWidth: 1280px;
$secondColor: #333333;
$redColor: var(--px-primary);

$main-header-height: 72px;
$main-footer-height: 66px;

$px-input-valid-color: #2ee548;
$px-input-error-color: #f40000;
$px-input-focused-color: var(--px-primary);

$stripe-bg: repeating-linear-gradient(45deg, #eeeeee, #eeeeee 3px, #f2f2f2 3px, #f2f2f2 7px);

$px-layout-xxs: 200px;
$px-layout-xs: 600px;
$px-layout-sm: 960px;
$px-layout-md: 1280px;
$px-layout-lg: 1360px;

$px-accent-color: var(--px-accent);
$px-warn-color: #ff3366;
$px-gray-color: #bfbfbf;
$px-white-color: #ffffff;

$tooltip-color: #0099b7;
$px-blue-arrow-size: 8px;

$base-skeleton-color: #f1f1f1;
$shine-skeleton-color: #f8f8f8;

@mixin arrow($direction, $color: $tooltip-color, $size: $px-blue-arrow-size, $position: false) {
  display: block;
  height: 0;
  width: 0;

  @if $direction == 'top' {
    bottom: calc(100% - 1px);
  } @else if $direction == 'bottom' {
    top: calc(100% - 1px);
  } @else if $direction == 'left' {
    right: calc(100% - 1px);
  } @else if $direction == 'right' {
    left: calc(100% - 1px);
  }

  @if $position == true {
    content: '';
    position: absolute;

    @if $direction == 'top' {
      left: 50%;
      margin-left: (-$size);
    } @else if $direction == 'bottom' {
      left: 50%;
      margin-left: (-$size);
    } @else if $direction == 'left' {
      top: 50%;
      margin-top: (-$size);
    } @else if $direction == 'right' {
      top: 50%;
      margin-top: (-$size);
    }
  }

  @if $direction == 'top' {
    border-top: $size solid transparent;
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-bottom: $size solid transparent;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid transparent;
    border-right: $size solid $color;
  }
}

//Fonts
@mixin light() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 200;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin reg() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin med() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 500;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin ita() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 400;
  font-style: italic;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin bold() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 700;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin ProximaSemiBold() {
  font-family: 'ProximaNova-Semibold', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin book() {
  font-family: 'ProximaNova-Regular', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

@mixin Gothambook() {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 300;
  font-style: normal;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}
