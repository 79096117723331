@use "sass:math";

$baseline-grid:            8px !default;
$layout-gutter-width:      ($baseline-grid * 2) !default;
$layout-breakpoint-xs:     600px !default;
$layout-breakpoint-sm:     960px !default;
$layout-breakpoint-md:     1280px !default;
$layout-breakpoint-lg:     1920px !default;



$baseline-grid:            8px !default;
$layout-gutter-width:      ($baseline-grid * 2) !default;
$layout-breakpoint-xs:     600px !default;
$layout-breakpoint-sm:     960px !default;
$layout-breakpoint-md:     1280px !default;
$layout-breakpoint-lg:     1920px !default;

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/

// Layout
// ------------------------------
@mixin flex-order-for-name($sizes:null) {
  @if $sizes == null {
    $sizes : '';

    [flex-order] {
     order : 0;
    }
  }

  @for $i from -20 through 20 {
    $order : '';
    $suffix : '';

    @each $s in $sizes {
      @if $s != '' { $suffix : '-#{$s}="#{$i}"'; }
      @else        { $suffix : '="#{$i}"';       }

      $order : '[flex-order#{$suffix}]';
    }

    #{$order} {
      order: #{$i};
    }
  }
}
@mixin offset-for-name($sizes:null) {
  @if $sizes == null { $sizes : ''; }

  @for $i from 0 through 19 {
    $offsets : '';
    $suffix : '';

    @each $s in $sizes {
      @if $s != '' { $suffix : '-#{$s}="#{$i * 5}"'; }
      @else        { $suffix : '="#{$i * 5}"';       }

      $offsets : $offsets + '[flex-offset#{$suffix}], ';
    }

    #{$offsets} {
      margin-left: #{$i * 5 + '%'};
    }
  }

  @each $i in 33 {
    $offsets : '';
    $suffix : '';

    @each $s in $sizes {
      @if $s != '' {  $suffix : '-#{$s}="#{$i}"';   }
      @else        {  $suffix : '="#{$i}"';         }

      $offsets : '[flex-offset#{$suffix}], ';
    }

    #{$offsets} {
      margin-left: calc(100% / 3);
    }
  }

  @each $i in 66  {
    $offsets : '';
    $suffix : '';

    @each $s in $sizes {
      @if $s != '' {  $suffix : '-#{$s}="#{$i}"';   }
      @else        {  $suffix : '="#{$i}"';         }

      $offsets : '[flex-offset#{$suffix}]';
    }

    #{$offsets} {
      margin-left: calc(200% / 3);
    }
  }
}

@mixin layout-for-name($name: null) {
  @if $name == null { $name : '';          }
  @if $name != ''   { $name : '-#{$name}'; }

  [layout#{$name}], [layout#{$name}="column"], [layout#{$name}="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  [layout#{$name}="column"] {  flex-direction: column;  }
  [layout#{$name}="row"]    {  flex-direction: row;     }
}

@mixin flex-properties-for-name($name: null) {
  $flexName: 'flex';
  @if $name != null {
    $flexName: 'flex-#{$name}';
    $name : '-#{$name}';
  } @else {
    $name : '';
  }

  [#{$flexName}]             { flex: 1;         box-sizing: border-box; }  // === flex: 1 1 0%;

  [#{$flexName}-grow]        { flex: 1 1 100%;  box-sizing: border-box; }
  [#{$flexName}-initial]     { flex: 0 1 auto;  box-sizing: border-box; }
  [#{$flexName}-auto]        { flex: 1 1 auto;  box-sizing: border-box; }
  [#{$flexName}-none]        { flex: 0 0 auto;  box-sizing: border-box; }

  // (1-20) * 5 = 0-100%
  @for $i from 0 through 20 {
    $value : #{$i * 5 + '%'};

    [#{$flexName}="#{$i * 5}"] {
      flex: 1 1 #{$value};
      max-width: #{$value};
      max-height: 100%;
      box-sizing: border-box;
    }

    [layout="row"] > [#{$flexName}="#{$i * 5}"] {
      flex: 1 1 #{$value};
      max-width: #{$value};
      max-height: 100%;
      box-sizing: border-box;

      // Required by Chrome M48+ due to http://crbug.com/546034
      //@if $i == 0 {  min-width: 0;  }
    }

    [layout="column"] > [#{$flexName}="#{$i * 5}"] {
      flex: 1 1 #{$value};
      max-width: 100%;
      max-height: #{$value};
      box-sizing: border-box;
    }

    [layout="row"] {
	    > [#{$flexName}="33"]   , > [#{$flexName}="33"]     {  flex: 1 1 33.33%;  max-width: 33.33%;  max-height: 100%; box-sizing: border-box; }
	    > [#{$flexName}="66"]   , > [#{$flexName}="66"]     {  flex: 1 1 66.66%;  max-width: 66.66%;  max-height: 100%; box-sizing: border-box; }
	  }

	  [layout="column"] {
	    > [#{$flexName}="33"]   , > [#{$flexName}="33"]     {  flex: 1 1 33.33%;  max-width: 100%;  max-height: 33.33%; box-sizing: border-box; }
	    > [#{$flexName}="66"]   , > [#{$flexName}="66"]     {  flex: 1 1 66.66%;  max-width: 100%;  max-height: 66.66%; box-sizing: border-box; }
	  }

    [layout#{$name}="row"] > [#{$flexName}="#{$i * 5}"] {
      flex: 1 1 #{$value};
      max-width: #{$value};
      max-height: 100%;
      box-sizing: border-box;

      // Required by Chrome M48+ due to http://crbug.com/546034
      //@if $i == 0 { min-width: 0; }
    }

    [layout#{$name}="column"] > [#{$flexName}="#{$i * 5}"] {
      flex: 1 1 #{$value};
      max-width: 100%;
      max-height: #{$value};
      box-sizing: border-box;

      // Required by Chrome M48+ due to http://crbug.com/546034
      @if $i == 0 { min-height: 0; }
    }
  }

  [layout#{$name}="row"] {
    > [#{$flexName}="33"]   , > [#{$flexName}="33"]     {  flex: 1 1 33.33%;  max-width: 33.33%;  max-height: 100%; box-sizing: border-box; }
    > [#{$flexName}="66"]   , > [#{$flexName}="66"]     {  flex: 1 1 66.66%;  max-width: 66.66%;  max-height: 100%; box-sizing: border-box; }

    // Required by Chrome M48+ due to http://crbug.com/546034
    //> [flex] { min-width: 0; }
  }

  [layout#{$name}="column"] {
    > [#{$flexName}="33"]   , > [#{$flexName}="33"]     {  flex: 1 1 33.33%;  max-width: 100%;  max-height: 33.33%; box-sizing: border-box; }
    > [#{$flexName}="66"]   , > [#{$flexName}="66"]     {  flex: 1 1 66.66%;  max-width: 100%;  max-height: 66.66%; box-sizing: border-box; }

    // Required by Chrome M48+ due to http://crbug.com/546034
    > [flex] { min-height: 0; }
  }

}
@mixin layout-align-for-name($suffix: null) {

  // Alignment attributes for layout containers' children
  // Arrange on the Main Axis
  // center, start, end, space-between, space-around
  // flex-start is the default for justify-content
  // ------------------------------

  $name: 'layout-align';
  @if $suffix != null {
    $name: 'layout-align-#{$suffix}';
  }

  [#{$name}],
  [#{$name}="start stretch"] // defaults
  {
    justify-content :flex-start;
    align-content : stretch;
    align-items: stretch;
  }
  // Main Axis Center
  [#{$name}="start"],
  [#{$name}="start start"],
  [#{$name}="start center"],
  [#{$name}="start end"],
  [#{$name}="start stretch"]
  {
    justify-content: flex-start;
  }

  // Main Axis Center
  [#{$name}="center"],
  [#{$name}="center start"],
  [#{$name}="center center"],
  [#{$name}="center end"],
  [#{$name}="center stretch"]
  {
    justify-content: center;
  }

  // Main Axis End
  [#{$name}="end"], //stretch
  [#{$name}="end center"],
  [#{$name}="end start"],
  [#{$name}="end end"],
  [#{$name}="end stretch"]
  {
    justify-content: flex-end;
  }

  // Main Axis Space Around
  [#{$name}="space-around"], //stretch
  [#{$name}="space-around center"],
  [#{$name}="space-around start"],
  [#{$name}="space-around end"],
    [#{$name}="space-around stretch"]
  {
    justify-content: space-around;
  }

  // Main Axis Space Between
  [#{$name}="space-between"], //stretch
  [#{$name}="space-between center"],
  [#{$name}="space-between start"],
  [#{$name}="space-between end"],
    [#{$name}="space-between stretch"]
  {
    justify-content: space-between;
  }


  // Arrange on the Cross Axis
  // center, start, end
  // stretch is the default for align-items
  // ------------------------------

  // Cross Axis Start
  [#{$name}="start start"],
  [#{$name}="center start"],
  [#{$name}="end start"],
  [#{$name}="space-between start"],
  [#{$name}="space-around start"]
  {
    align-items: flex-start;
    align-content: flex-start;
  }

  // Cross Axis Center
  [#{$name}="start center"],
  [#{$name}="center center"],
  [#{$name}="end center"],
  [#{$name}="space-between center"],
  [#{$name}="space-around center"]
  {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }

  // Cross Axis Center IE overflow fix
  [#{$name}="start center"] > *,
  [#{$name}="center center"] > *,
  [#{$name}="end center"] > *,
  [#{$name}="space-between center"] > *,
  [#{$name}="space-around center"] > *
  {
    max-width: 100%;
    box-sizing: border-box;
  }

  // Cross Axis End
  [#{$name}="start end"],
  [#{$name}="center end"],
  [#{$name}="end end"],
  [#{$name}="space-between end"],
  [#{$name}="space-around end"]
  {
    align-items: flex-end;
    align-content: flex-end;
  }

  // Cross Axis  stretch
  [#{$name}="start stretch"],
  [#{$name}="center stretch"],
  [#{$name}="end stretch"],
  [#{$name}="space-between stretch"],
  [#{$name}="space-around stretch"]
  {
    align-items: stretch;
    align-content: stretch;
  }
}
@mixin layout-padding-margin() {

  [layout-padding] > [flex-sm],   [layout-padding] > [flex-lt-md] {
    padding:  math.div($layout-gutter-width, 4);
  }
  [layout-padding],
  [layout-padding] > [flex],
  [layout-padding] > [flex-gt-sm],
  [layout-padding] > [flex-md],
  [layout-padding] > [flex-lt-lg]
  {
    padding:  math.div($layout-gutter-width, 2);
  }
  [layout-padding] > [flex-gt-md],
  [layout-padding] > [flex-lg]
  {
    padding: math.div($layout-gutter-width, 1);
  }

  [layout-margin] > [flex-sm],
  [layout-margin] > [flex-lt-md]
  {
    margin: math.div($layout-gutter-width, 4);
  }

  [layout-margin],
  [layout-margin]  > [flex],
  [layout-margin]  > [flex-gt-sm],
  [layout-margin]  > [flex-md],
  [layout-margin]  > [flex-lt-lg]
  {
    margin: math.div($layout-gutter-width, 2);
  }

  [layout-margin]  > [flex-gt-md],
  [layout-margin]  > [flex-lg]
  {
    margin: math.div($layout-gutter-width, 1);
  }

  [layout-wrap] {
    flex-wrap: wrap;
  }

  [layout-nowrap] {
      flex-wrap: nowrap;
  }

  [layout-fill] {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

@mixin layouts_for_breakpoint($name:null) {
    @include flex-order-for-name($name);
    @include offset-for-name($name);
    @include layout-align-for-name($name);

    @include flex-properties-for-name($name);
    @include layout-for-name($name);
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */


@include layouts_for_breakpoint();
@include layout-padding-margin();


/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */


@media (max-width: $layout-breakpoint-xs - 1) {
  // Xtra-SMALL  SCREEN
  [hide-xs], [hide] {
    &:not([show-xs]):not([show]) {
      display: none;
    }
  }
  @include layouts_for_breakpoint(xs);
}

@media (min-width: $layout-breakpoint-xs) {
  // BIGGER THAN Xtra-SMALL SCREEN
  @include layouts_for_breakpoint(gt-xs);

}

@media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm - 1) {
  // SMALL SCREEN
  [hide], [hide-gt-xs] {
    &:not([show-gt-xs]):not([show-sm]):not([show]) {
      display: none;
    }
  }
  [hide-sm]:not([show-gt-xs]):not([show-sm]):not([show]) {
    display: none;
  }
  @include layouts_for_breakpoint(sm);
}

@media (min-width: $layout-breakpoint-sm) {
  // BIGGER THAN SMALL SCREEN
  @include layouts_for_breakpoint(gt-sm);

}

@media (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md - 1) {
  // MEDIUM SCREEN
  [hide], [hide-gt-xs], [hide-gt-sm] {
      &:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]) {
        display: none;
      }
    }
    [hide-md]:not([show-md]):not([show]) {
      display: none;
    }
  @include layouts_for_breakpoint(md);
}

@media (min-width: $layout-breakpoint-md) {
  // BIGGER THAN MEDIUM SCREEN
  @include layouts_for_breakpoint(gt-md);
}

@media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg - 1) {
  // LARGE SCREEN
  [hide],[hide-gt-xs], [hide-gt-sm], [hide-gt-md] {
      &:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]) {
        display: none;
      }
    }
    [hide-lg]:not([show-lg]):not([show]) {
      display: none;
    }

  @include layouts_for_breakpoint(lg);
}

@media (min-width: $layout-breakpoint-lg) {
  // BIGGER THAN LARGE SCREEN
  @include layouts_for_breakpoint(gt-lg);
  @include layouts_for_breakpoint(xl);

  // BIGGER THAN LARGE SCREEN
  [hide], [hide-gt-xs], [hide-gt-sm], [hide-gt-md], [hide-gt-lg] {
    &:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]) {
      display: none;
    }
  }
  [hide-xl]:not([show-xl]):not([show-gt-lg]):not([show]) {
    display: none;
  }
}

@media print {
  // PRINT
  @include layouts_for_breakpoint(print);

  [hide-print]:not([show-print]):not([show]) {
    display: none;
  }
}


// Required by Chrome M48+ due to http://crbug.com/546034
[layout="row"] > [flex="flex"] {
  min-width: 0;
}
