.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    transform: scale3d(1.03, 1.03, 1.03);
    opacity: 1;
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.bounceIn {
  animation-name: bounceIn;
  animation-duration: 0.75s;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.animate-if.ng-enter,
.animate-if.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms;
}

.animate-if.ng-enter,
.animate-if.ng-enter-prepare,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity: 1;
}

.switchAnimFade {
  transition: 0.5s linear opacity;
  transition-delay: 0.5s;
}

.switchAnimFade.ng-enter {
  opacity: 0;
}

.switchAnimFade.ng-enter-active {
  opacity: 1;
}

.switchAnimFade.ng-leave {
  opacity: 1;
}

.switchAnimFade.ng-leave-active {
  opacity: 0;
}
