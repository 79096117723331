/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use '@angular/material' as mat;
@use 'libs/mds/styles' as mds;
@use 'libs/pui/styles' as pui;
@import './assets/sass/material.theme';
@import 'assets/assets-base/sass/animations';
@import 'assets/assets-base/sass/fonts';
@import './assets/sass/pss-font';
@import './assets/sass/variables';
@import 'assets/assets-base/sass/global';
@import './assets/sass/material.components';
@import './assets/sass/material.layout-attributes';
@import 'assets/assets-base/sass/components';
@import 'assets/assets-base/sass/dropdown';

@include pui.m-imports-prepare;
@include mds.m-fonts-import;
@include mds.m-init-theme(pink);

// Theming
@include pui.m-init-theme(('pui', 'mds'), ':root', map.get(mds.$theme-configs, pink));

body {
  --accent-darken-legacy: #{mat.get-color-from-palette($candy-app-accent, darker)};
  --accent-300-legacy: #{mat.get-color-from-palette($candy-app-accent, 300)};
  --accent-400-legacy: #{mat.get-color-from-palette($candy-app-accent, 400)};
  --accent-500-legacy: #{mat.get-color-from-palette($px-accent, 500)};
  --accent-800-legacy: #{mat.get-color-from-palette($candy-app-accent, 800)};
  --acent-200-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, '200-contrast')};
  --accent-300-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, '300-contrast')};
  --accent-400-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, '400-contrast')};
  --accent-500-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, '500-contrast')};
  --accent-600-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, '600-contrast')};
  --accent-A100-contrast-legacy: #{mat.get-color-from-palette($candy-app-accent, 'A100-contrast')};

  margin: 0;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  text-size-adjust: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

$themeVariables: (
  --px-primary-rgb: (
    229,
    46,
    92,
  ),
  --px-primary-r: 229,
  --px-primary-g: 46,
  --px-primary-b: 92,
  --px-primary: rgb(var(--px-primary-r) var(--px-primary-g) var(--px-primary-b)),
  --px-primary-darken-r: 216,
  --px-primary-darken-g: 27,
  --px-primary-darken-b: 96,
  --px-primary-darken-1: rgb(var(--px-primary-darken-r) var(--px-primary-darken-g) var(--px-primary-darken-b)),
  --px-accent-r: 79,
  --px-accent-g: 173,
  --px-accent-b: 255,
  --px-accent: rgb(var(--px-accent-r) var(--px-accent-g) var(--px-accent-b)),
  --px-accent-darken-r-1: 51,
  --px-accent-darken-g-1: 160,
  --px-accent-darken-b-1: 255,
  --px-accent-darken-1: rgb(var(--px-accent-darken-r-1) var(--px-accent-darken-g-1) var(--px-accent-darken-b-1)),
  --px-accent-darken-r-2: 0,
  --px-accent-darken-g-2: 120,
  --px-accent-darken-b-2: 224,
  --px-accent-darken-2: rgb(var(--px-accent-darken-r-2) var(--px-accent-darken-g-2) var(--px-accent-darken-b-2)),
  --px-accent-lighten-r-1: 106,
  --px-accent-lighten-g-1: 186,
  --px-accent-lighten-b-1: 255,
  --px-accent-lighten-1: rgb(var(--px-accent-lighten-r-1) var(--px-accent-lighten-g-1) var(--px-accent-lighten-b-1)),
  --px-accent-lighten-r-2: 153,
  --px-accent-lighten-g-2: 207,
  --px-accent-lighten-b-2: 255,
  --px-accent-lighten-2: rgb(var(--px-accent-lighten-r-2) var(--px-accent-lighten-g-2) var(--px-accent-lighten-b-2)),
  --px-drop-zone-bg-color:
    rgb(var(--px-accent-lighten-r-2) var(--px-accent-lighten-g-2) var(--px-accent-lighten-b-2) / 10%),
  --px-drop-zone-border-color: var(--px-accent-darken-1),
  --px-primary-200: #f092ab,
  --px-primary-300: #ec668b,
  --pr-primary-500: #e52e5c,
  --mds-font-family-base: (
    'Roboto',
    sans-serif,
  ),
);

@include mds.legacy-px-m-theme-create($themeVariables, pss);

// Typography
@include pui.m-init-typography('mds', ':root', map.get(mds.$theme-typography, 'base'));
@include pui.m-init-typography('pui', ':root', map.get(mds.$theme-typography, 'base'));
