*,
::after,
::before {
  box-sizing: border-box;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

a.disabled {
  cursor: default;
  pointer-events: none;
}

// for themes
html {
  font-size: 6.25%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--mds-sys-color-on-surface-variant);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.42857143;
  background-color: #fafafa;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .static-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;

    pss-upgrade-footer {
      margin-top: auto;
    }
  }

  .inner-wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

.no_selection {
  user-select: none;
}

[hidden] {
  display: none !important;
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// One line block alignment
.oneLineBlock {
  .leftBlock {
    float: left;
  }

  .centerBlock {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rightBlock {
    float: right;
  }
}

/* HEADINGS */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.1;
}

.h4,
.h5,
.h6,
dl,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h4,
h4 {
  font-size: 18px;
}

/* GLOBAL TOOLS */
.preload-fonts {
  position: absolute;
  top: -9999px;
  opacity: 0;

  .roboto {
    &::before {
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      content: '';
    }

    &::after {
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      content: '';
    }
  }
}

// Loading dots - Start
.loading_dots {
  span {
    animation: loading_dots 1.4s both infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes loading_dots {
  0% {
    opacity: 0.3;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Loading dots - End

// for mat-datepicker on dropdown
.cdk-overlay-container {
  z-index: 100000;
}
