//Proxima Nova
// @import url("//hello.myfonts.net/count/28c725");
@font-face {
  font-family: 'ProximaNovaT-Thin';
  src: url('/assets/fonts/proxima-nova/28C725_E_0.eot');
  src:
    url('/assets/fonts/proxima-nova/28C725_E_0.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/28C725_E_0.woff') format('woff'),
    url('/assets/fonts/proxima-nova/28C725_E_0.ttf') format('truetype');
}

/*
@font-face {
	font-family: 'ProximaNova-Light';
	src: url('fonts/28C725_11_0.eot');
	src: url('fonts/28C725_11_0.eot?#iefix') format('embedded-opentype'),
		 url('fonts/28C725_11_0.woff') format('woff'),
		 url('fonts/28C725_11_0.ttf') format('truetype');
}
*/
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('/assets/fonts/proxima-nova/28C725_13_0.eot');
  src:
    url('/assets/fonts/proxima-nova/28C725_13_0.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/28C725_13_0.woff') format('woff'),
    url('/assets/fonts/proxima-nova/28C725_13_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('/assets/fonts/proxima-nova/28C725_12_0.eot');
  src:
    url('/assets/fonts/proxima-nova/28C725_12_0.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/28C725_12_0.woff') format('woff'),
    url('/assets/fonts/proxima-nova/28C725_12_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('/assets/fonts/proxima-nova/28C725_14_0.eot');
  src:
    url('/assets/fonts/proxima-nova/28C725_14_0.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/28C725_14_0.woff') format('woff'),
    url('/assets/fonts/proxima-nova/28C725_14_0.ttf') format('truetype');
}
