//input group
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  color: #555555;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  color: #555555;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child, .dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:first-child, :last-child),
.input-group-btn:not(:first-child, :last-child),
.input-group .form-control:not(:first-child, :last-child) {
  border-radius: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.copied {
  position: absolute;
  z-index: 10000000;
  margin-top: 0;
  color: var(--mds-sys-color-primary);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  transform: translate(-50%, 0);
  opacity: 1;
  will-change: opacity, transform;

  &.up {
    transform: translate(-50%, -15px);
    opacity: 0;
    transition:
      transform 300ms ease-out,
      opacity 300ms ease-out;
  }
}

// Loading dots - Start
.loading_dots {
  span {
    animation: loading_dots 1.4s both infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes loading_dots {
  0% {
    opacity: 0.3;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Loading dots - End

.md-content {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// for mat-datepicker on dropdown
.cdk-overlay-container {
  z-index: 100000;
}
