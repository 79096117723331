@use '@angular/material' as mat;

.dropdown,
.dropup {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1011;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 10px 0 0;
  padding: 5px 0;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid mat.get-color-from-palette($candy-app-accent, 300);
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 19%);
  animation-duration: 1s;
  animation-fill-mode: both;

  &.show {
    animation: fadeIn 150ms;
  }

  &.closing {
    display: block;
    animation: fadeOut 150ms;
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-with-arrow,
.dropdown-with-bottom-arrow {
  overflow: visible;
}

.dropdown-with-arrow::before,
.dropdown-with-arrow::after,
.dropdown-with-bottom-arrow::before,
.dropdown-with-bottom-arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  content: ' ';
}

.dropdown-with-arrow::before,
.dropdown-with-bottom-arrow::before {
  top: -9px;
  right: auto;
  bottom: auto;
  left: 40px;
  margin: 0;
  border-width: 9px;
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, 25%);

  .is-placed-in-body & {
    top: 2px;
  }
}

.dropdown-with-arrow::after,
.dropdown-with-bottom-arrow::after {
  top: -8px;
  left: 41px;
  border-width: 8px;
  border-top-width: 0;
  border-bottom-color: #fff;

  .is-placed-in-body & {
    top: 3px;
  }
}

.dropdown-with-arrow.dropdown-menu-right::before,
.dropdown-with-bottom-arrow.dropdown-menu-right::before {
  right: 40px;
  left: auto;
}

.dropdown-with-arrow.dropdown-menu-right::after,
.dropdown-with-bottom-arrow.dropdown-menu-right::after {
  right: 41px;
  left: auto;
}

.dropdown-with-arrow.dropdown-menu-center::before,
.dropdown-with-bottom-arrow.dropdown-menu-center::before {
  left: calc(50% - 9px);
}

.dropdown-with-arrow.dropdown-menu-center::after,
.dropdown-with-bottom-arrow.dropdown-menu-center::after {
  left: calc(50% - 8px);
}
