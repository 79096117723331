@use '@angular/material' as mat;
@import '../../../../../node_modules/@angular/material/theming';
@import 'variables';

// $mat-pink
$px-pink: (
  //50: #fce4ec,
  100: #f8bbd0,
  // TODO: Not in use at the moment. A good candidate to be replaced with some light pink color in future.
  //200: #f48fb1,
  //300: #f06292,
  //400: #ec407a,
  500: #e52e5c,
  // e91e63
  600: #d81b60,
  //700: #c2185b,
  //800: #ad1457,
  //900: #880e4f,
  //A100: #ff80ab,
  //A200: #e10f44, // ff4081
  //A400: #f50057,
  //A700: #c51162,
  contrast:
    (
      //50: $dark-primary-text,
      100: $dark-primary-text,
      //200: $dark-primary-text,
      //300: $dark-primary-text,
      //400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      //700: $light-primary-text,
      //800: $light-primary-text,
      //900: $light-primary-text,
      //A100: $dark-primary-text,
      //A200: $light-primary-text,
      //A400: $light-primary-text,
      //A700: $light-primary-text,
    )
);

// $mat-grey
$px-accent: (
  50: #fafafa,
  100: #f2f2f2,
  200: transparent,
  300: #e5e5e5,
  400: #d8d8d8,
  500: #9e9e9e,
  600: $px-accent-color,
  700: #616161,
  800: #0099b7,
  //900: #212121,
  A100: #ffffff,
  A200: #ffffff,
  A400: #626262,
  // eeeeee
  //A700: #2962ff,
  disabled: rgba(229, 229, 229, 0.4),
  raised-disabled: rgba(204, 204, 204, 0.12),
  contrast: (
    50: $dark-primary-text,
    //100: $dark-primary-text,
    200: $secondColor,
    300: #bfbfbf,
    400: #000,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    //900: $light-primary-text,
    A100: #4a4a4a,
    A200: #979797,
    A400: $light-primary-text,
    //A700: $light-primary-text,
    disabled: rgba(0, 0, 0, 0.38),
    raised-disabled: rgba(255, 255, 255, 0.3),
  ),
);

$px-warn: (
  //50: #e3f2fd,
  100: $redColor,
  // bbdefb
  //200: #90caf9,
  //300: #64b5f6,
  //400: #42a5f5,
  500: $px-input-error-color,
  // 2196f3
  //600: #1e88e5,
  700: $px-warn-color,
  //800: #1565c0,
  //900: #0d47a1,
  //A100: #82b1ff,
  //A200: #448aff,
  //A400: #2979ff,
  //A700: #2962ff,
  contrast:
    (
      //50: $dark-primary-text,
      //      100: #111,
      //200: $dark-primary-text,
      //300: $dark-primary-text,
      //400: $dark-primary-text,
      //      500: #000,
      //600: $light-primary-text,
      //      700: $light-primary-text,
      //800: $light-primary-text,
      //900: $light-primary-text,
      //A100: $dark-primary-text,
      //A200: $light-primary-text,
      //A400: $light-primary-text,
      //A700: $light-primary-text,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat.define-palette($px-pink, 500, 100, 600);
$candy-app-accent: mat.define-palette($px-accent, 'A400', 50);
$candy-app-warn: mat.define-palette($px-warn);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// TODO: It's will be removed after create UI kit (base mat theme reload custom styles in components form outside)
.px-thumbnail-size .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: mat.get-color-from-palette($candy-app-primary, 500); //
  border: none;
}
