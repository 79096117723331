@use '@angular/material' as mat;
@import 'theme';

*,
::after,
::before,
input[type='search'] {
  box-sizing: border-box;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

a.disabled {
  cursor: default;
  pointer-events: none;
}

.second-color {
  color: $secondColor;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: ' ';
}

.clearfix::after {
  clear: both;
}

// for themes
html {
  font-size: 6.25%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: mat.get-color-from-palette($candy-app-accent, '200-contrast'); // TODO: Change to correct token from theme
  font-size: 14px;
  font-family: $fontFamilyBase;
  line-height: 1.42857143;
  background-color: #fafafa;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .static-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;

    pss-upgrade-footer {
      margin-top: auto;
    }
  }

  .inner-wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

.no_selection {
  user-select: none;
}

[hidden] {
  display: none !important;
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// One line block alignment
.oneLineBlock {
  .leftBlock {
    float: left;
  }

  .centerBlock {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rightBlock {
    float: right;
  }
}

.flex-auto-safari-ie-fix {
  flex: 0 0 auto !important; //WA from: https://github.com/angular/material/issues/5441 (IE/Safari)
}

/* COLORS */
.color-white {
  color: #ffffff;
}

/* FONTS */
.font-5 {
  font-size: 10px;
}

/* HEADINGS */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.1;
}

.h4,
.h5,
.h6,
dl,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h4,
h4 {
  font-size: 18px;
}

/* GLOBAL TOOLS */
.photoNameTooltipTest {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: -2;
  max-width: 180px;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  word-wrap: break-word;
  border-radius: 4px;
  opacity: 0;
}

.preload-fonts {
  position: absolute;
  top: -9999px;
  opacity: 0;

  .roboto {
    &::before {
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      content: '';
    }

    &::after {
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      content: '';
    }
  }

  .pss-icons {
    &::before {
      font-family: 'pss-icons', serif;
      content: '';
    }
  }
}
