$icomoon-font-path: '/assets/fonts/icons' !default;
$icomoon-font-family: 'pss-icons' !default;

$pss-icons_01-facebook: "\e001";
$pss-icons_02-twitter: "\e002";
$pss-icons_03-pintrest: "\e003";
$pss-icons_04-play: "\e004";
$pss-icons_05-pause: "\e005";
$pss-icons_06-replay: "\e006";
$pss-icons_07-download: "\e007";
$pss-icons_08-volume: "\e008";
$pss-icons_09-mute: "\e009";
$pss-icons_10-fullscreen: "\e00a";
$pss-icons_11-notfullscreen: "\e00b";
$pss-icons_12-share: "\e00c";
$pss-icons_13-arrow: "\e900";
$pss-icons_14-erase: "\e91c";
$pss-icons_29-link1: "\e93a";
$pss-icons_01-pixellu-logo-rounded: "\e93e";
$pss-icons_02-smartslides-logo: "\e901";
$pss-icons_03-smartslides-icon: "\e902";
$pss-icons_04-slideshow-manager: "\e903";
$pss-icons_05-beat-matching: "\e904";
$pss-icons_06-my-uploads: "\e905";
$pss-icons_07-transition: "\e906";
$pss-icons_08-image: "\e907";
$pss-icons_09-add-slideshow: "\e908";
$pss-icons_10-add-logo: "\e909";
$pss-icons_11-close: "\e90a";
$pss-icons_13-chrome: "\e90b";
$pss-icons_14-firefox: "\e90c";
$pss-icons_15-featured-image: "\e90d";
$pss-icons_16-slideshow-thumbnail_edit: "\e90e";
$pss-icons_17-slideshow-thumbnail_duplicate: "\e90f";
$pss-icons_18-slideshow-thumbnail_download: "\e910";
$pss-icons_19-slideshow-thumbnail_share: "\e911";
$pss-icons_20-slideshow-thumbnail_trash: "\e912";
$pss-icons_21-slideshow-thumbnail_play: "\e913";
$pss-icons_22-search: "\e914";
$pss-icons_23-slideshow-settings: "\e915";
$pss-icons_24-preferences-account: "\e916";
$pss-icons_25-preferences-plan: "\e917";
$pss-icons_26-preferences-domain: "\e918";
$pss-icons_27-preferences-analytics: "\e919";
$pss-icons_28-preferences-seo: "\e91a";
$pss-icons_29-link: "\e91b";
$pss-icons_30-info: "\e94b";
$pss-icons_31-question: "\e91d";
$pss-icons_32-publish-confirmationIcon: "\e91e";
$pss-icons_33-segment-music-note: "\e91f";
$pss-icons_34-redistribute: "\e920";
$pss-icons_35-add-images-button: "\e921";
$pss-icons_36-warning-error: "\e922";
$pss-icons_38-play: "\e923";
$pss-icons_39-pause: "\e924";
$pss-icons_40-pin-maximized: "\e925";
$pss-icons_41-unpin: "\e926";
$pss-icons_42-user-profile: "\e927";
$pss-icons_43-library-music-note: "\e928";
$pss-icons_44-angle-left: "\e929";
$pss-icons_45-angle-right: "\e92a";
$pss-icons_46-angle-down: "\e92b";
$pss-icons_47-calendar: "\e92c";
$pss-icons_48-add-segment: "\e92d";
$pss-icons_49-chevron-left: "\e92e";
$pss-icons_50-caret-up: "\e92f";
$pss-icons_51-caret-down: "\e930";
$pss-icons_52-close-outline: "\e931";
$pss-icons_53-success-check: "\e932";
$pss-icons_54-edit-track-data: "\e933";
$pss-icons_55-refresh: "\e934";
$pss-icons_56-favorite-outline: "\e936";
$pss-icons_57-favorite-solid: "\e937";
$pss-icons_58-lyrics: "\e935";
$pss-icons_59-preferences-language: "\e938";
$pss-icons_60-copy-link: "\e939";
$pss-icons_61-code: "\e93b";
$pss-icons_62-hd: "\e93c";
$pss-icons_63-4k: "\e93d";
$pss-icons_64-psf-logo: "\e93f";
$pss-icons_65-preview-back: "\e940";
$pss-icons_66-sort: "\e941";
$pss-icons_68-more-vert: "\e942";
$pss-icons_69-grid-spacing-thin: "\e943";
$pss-icons_70-grid-spacing-wide: "\e944";
$pss-icons_71-zoom-in: "\e945";
$pss-icons_72-copy-link: "\e946";
$pss-icons_73-view-gallery-large: "\e947";
$pss-icons_74-view-gallery-small: "\e948";
$pss-icons_75-edge: "\e949";
$pss-icons_76-safari: "\e94a";
$pss-icons_77-image-folder: "\e94e";
$pss-icons_78-add: "\e94c";
$pss-icons_79-edit: "\e94d";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tkxdw9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tkxdw9#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tkxdw9') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tkxdw9') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tkxdw9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="pss-"], [class*=" pss-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pss-icons_01-pixellu-logo-rounded {
  &:before {
    content: $pss-icons_01-pixellu-logo-rounded;
  }
}
.pss-icons_02-smartslides-logo {
  &:before {
    content: $pss-icons_02-smartslides-logo;
  }
}
.pss-icons_03-smartslides-icon {
  &:before {
    content: $pss-icons_03-smartslides-icon;
  }
}
.pss-icons_04-slideshow-manager {
  &:before {
    content: $pss-icons_04-slideshow-manager;
  }
}
.pss-icons_05-beat-matching {
  &:before {
    content: $pss-icons_05-beat-matching;
  }
}
.pss-icons_06-my-uploads {
  &:before {
    content: $pss-icons_06-my-uploads;
  }
}
.pss-icons_07-transition {
  &:before {
    content: $pss-icons_07-transition;
  }
}
.pss-icons_08-image {
  &:before {
    content: $pss-icons_08-image;
  }
}
.pss-icons_09-add-slideshow {
  &:before {
    content: $pss-icons_09-add-slideshow;
  }
}
.pss-icons_10-add-logo {
  &:before {
    content: $pss-icons_10-add-logo;
  }
}
.pss-icons_11-close {
  &:before {
    content: $pss-icons_11-close;
  }
}
.pss-icons_13-chrome {
  &:before {
    content: $pss-icons_13-chrome;
  }
}
.pss-icons_14-firefox {
  &:before {
    content: $pss-icons_14-firefox;
  }
}
.pss-icons_15-featured-image {
  &:before {
    content: $pss-icons_15-featured-image;
  }
}
.pss-icons_16-slideshow-thumbnail_edit {
  &:before {
    content: $pss-icons_16-slideshow-thumbnail_edit;
  }
}
.pss-icons_17-slideshow-thumbnail_duplicate {
  &:before {
    content: $pss-icons_17-slideshow-thumbnail_duplicate;
  }
}
.pss-icons_18-slideshow-thumbnail_download {
  &:before {
    content: $pss-icons_18-slideshow-thumbnail_download;
  }
}
.pss-icons_19-slideshow-thumbnail_share {
  &:before {
    content: $pss-icons_19-slideshow-thumbnail_share;
  }
}
.pss-icons_20-slideshow-thumbnail_trash {
  &:before {
    content: $pss-icons_20-slideshow-thumbnail_trash;
  }
}
.pss-icons_21-slideshow-thumbnail_play {
  &:before {
    content: $pss-icons_21-slideshow-thumbnail_play;
  }
}
.pss-icons_22-search {
  &:before {
    content: $pss-icons_22-search;
  }
}
.pss-icons_23-slideshow-settings {
  &:before {
    content: $pss-icons_23-slideshow-settings;
  }
}
.pss-icons_24-preferences-account {
  &:before {
    content: $pss-icons_24-preferences-account;
  }
}
.pss-icons_25-preferences-plan {
  &:before {
    content: $pss-icons_25-preferences-plan;
  }
}
.pss-icons_26-preferences-domain {
  &:before {
    content: $pss-icons_26-preferences-domain;
  }
}
.pss-icons_27-preferences-analytics {
  &:before {
    content: $pss-icons_27-preferences-analytics;
  }
}
.pss-icons_28-preferences-seo {
  &:before {
    content: $pss-icons_28-preferences-seo;
  }
}
.pss-icons_29-link {
  &:before {
    content: $pss-icons_29-link;
  }
}
.pss-icons_30-info {
  &:before {
    content: $pss-icons_30-info;
  }
}
.pss-icons_31-question {
  &:before {
    content: $pss-icons_31-question;
  }
}
.pss-icons_32-publish-confirmationIcon {
  &:before {
    content: $pss-icons_32-publish-confirmationIcon;
  }
}
.pss-icons_33-segment-music-note {
  &:before {
    content: $pss-icons_33-segment-music-note;
  }
}
.pss-icons_34-redistribute {
  &:before {
    content: $pss-icons_34-redistribute;
  }
}
.pss-icons_35-add-images-button {
  &:before {
    content: $pss-icons_35-add-images-button;
  }
}
.pss-icons_36-warning-error {
  &:before {
    content: $pss-icons_36-warning-error;
  }
}
.pss-icons_38-play {
  &:before {
    content: $pss-icons_38-play;
  }
}
.pss-icons_39-pause {
  &:before {
    content: $pss-icons_39-pause;
  }
}
.pss-icons_40-pin-maximized {
  &:before {
    content: $pss-icons_40-pin-maximized;
  }
}
.pss-icons_41-unpin {
  &:before {
    content: $pss-icons_41-unpin;
  }
}
.pss-icons_42-user-profile {
  &:before {
    content: $pss-icons_42-user-profile;
  }
}
.pss-icons_43-library-music-note {
  &:before {
    content: $pss-icons_43-library-music-note;
  }
}
.pss-icons_44-angle-left {
  &:before {
    content: $pss-icons_44-angle-left;
  }
}
.pss-icons_45-angle-right {
  &:before {
    content: $pss-icons_45-angle-right;
  }
}
.pss-icons_46-angle-down {
  &:before {
    content: $pss-icons_46-angle-down;
  }
}
.pss-icons_47-calendar {
  &:before {
    content: $pss-icons_47-calendar;
  }
}
.pss-icons_48-add-segment {
  &:before {
    content: $pss-icons_48-add-segment;
  }
}
.pss-icons_49-chevron-left {
  &:before {
    content: $pss-icons_49-chevron-left;
  }
}
.pss-icons_50-caret-up {
  &:before {
    content: $pss-icons_50-caret-up;
  }
}
.pss-icons_51-caret-down {
  &:before {
    content: $pss-icons_51-caret-down;
  }
}
.pss-icons_52-close-outline {
  &:before {
    content: $pss-icons_52-close-outline;
  }
}
.pss-icons_53-success-check {
  &:before {
    content: $pss-icons_53-success-check;
  }
}
.pss-icons_54-edit-track-data {
  &:before {
    content: $pss-icons_54-edit-track-data;
  }
}
.pss-icons_55-refresh {
  &:before {
    content: $pss-icons_55-refresh;
  }
}
.pss-icons_56-favorite-outline {
  &:before {
    content: $pss-icons_56-favorite-outline;
  }
}
.pss-icons_57-favorite-solid {
  &:before {
    content: $pss-icons_57-favorite-solid;
  }
}
.pss-icons_58-lyrics {
  &:before {
    content: $pss-icons_58-lyrics;
  }
}
.pss-icons_59-preferences-language {
  &:before {
    content: $pss-icons_59-preferences-language;
  }
}
.pss-icons_60-copy-link {
  &:before {
    content: $pss-icons_60-copy-link;
  }
}
.pss-icons_61-code {
  &:before {
    content: $pss-icons_61-code;
  }
}
.pss-icons_62-hd {
  &:before {
    content: $pss-icons_62-hd;
  }
}
.pss-icons_63-4k {
  &:before {
    content: $pss-icons_63-4k;
  }
}
.pss-icons_64-psf-logo {
  &:before {
    content: $pss-icons_64-psf-logo;
  }
}
.pss-icons_65-preview-back {
  &:before {
    content: $pss-icons_65-preview-back;
  }
}
.pss-icons_66-sort {
  &:before {
    content: $pss-icons_66-sort;
  }
}
.pss-icons_68-more-vert {
  &:before {
    content: $pss-icons_68-more-vert;
  }
}
.pss-icons_69-grid-spacing-thin {
  &:before {
    content: $pss-icons_69-grid-spacing-thin;
  }
}
.pss-icons_70-grid-spacing-wide {
  &:before {
    content: $pss-icons_70-grid-spacing-wide;
  }
}
.pss-icons_71-zoom-in {
  &:before {
    content: $pss-icons_71-zoom-in;
  }
}
.pss-icons_72-copy-link {
  &:before {
    content: $pss-icons_72-copy-link;
  }
}
.pss-icons_73-view-gallery-large {
  &:before {
    content: $pss-icons_73-view-gallery-large;
  }
}
.pss-icons_74-view-gallery-small {
  &:before {
    content: $pss-icons_74-view-gallery-small;
  }
}
.pss-icons_75-edge {
  &:before {
    content: $pss-icons_75-edge;
  }
}
.pss-icons_76-safari {
  &:before {
    content: $pss-icons_76-safari;
  }
}
.pss-icons_77-image-folder {
  &:before {
    content: $pss-icons_77-image-folder;
  }
}
.pss-icons_78-add {
  &:before {
    content: $pss-icons_78-add;
  }
}
.pss-icons_79-edit {
  &:before {
    content: $pss-icons_79-edit;
  }
}

